import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Header from '../components/header';
import Layout from '../components/layout';
import Markdown from '../components/markdown';
import SEO from '../components/SEO';
import SectionOpener from '../sections/section-opener/section-opener';

class StaticPage extends Component {
  render() {
    const { data, location } = this.props;
    const { contentfulStaticPages: page } = data;

    return (
      <Layout navbarIsInverted={false}>
        <SEO title={page.title} metadata={page.metadata} />
        {page.title && (
          <SectionOpener openerType="static-page">
            <Header heading={page.title} />
          </SectionOpener>
        )}
        {page.body && (
          <div className="content-body">
            <div className="content-body__content">
              <Markdown markdown={page.body.childMarkdownRemark.html} isChapter />
            </div>
          </div>
        )}
      </Layout>
    );
  }
}

export default StaticPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulStaticPages(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

StaticPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
